import React from 'react';

import './Footer.scss'


function Footer() {

    return <div></div>;
}

export default Footer;