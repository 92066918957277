import React from 'react';

import './Header.scss'

function Header() {

    return (
        <div className='header'>
            <span className='header__text'>ENRIQUE'S ARBITRAGE DASHBOARD</span>
        </div>
    );
}

export default Header;